<template>
    <div class="ftp-servers" id="ftp-servers">
        <h1>FTP servers</h1>
    </div>
</template>

<script>
export default {
data() {
    return {
        title:'FTP Servers',
    }
},
mounted() {
    document.title = this.title;
},
}
</script>

<style scoped>
    
</style>